<template>
  <div class="content-box sss">
    <div class="header" :class="{ active: headerScroll }">
      <div class="logo">
        <img src="../../assets/home/logo.png" alt="logo" />
      </div>
    </div>
    <div class="content">
      <div class="sign">
        <div class="signInfo">
          <div class="textInfo">
            <!-- <div class="title">联动支付· 便捷生活</div>
            <div class="signs">安全 便捷 智能</div> -->
            <img src="../../assets/home/text.png" width="100%" />
          </div>
          <div class="gifBox">
            <div class="gif">
              <img src="../../assets/home/gif.gif" />
            </div>
          </div>
        </div>
      </div>
      <!-- 产品介绍 -->
      <div class="introduceBOX wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">产品介绍</div>
          <div class="titSign"><img src="../../assets/home/titSign.png" width="100%" /></div>
        </div>
        <div class="introduce">
          <div>联动Pay是-款为商户和服务商开发的APP，根据使用者的不同身份触达不同功能，</div>
          <div>为您提供安全、使捷、智能化服务，商户管理因此而变得更加高效。</div>
          <div>合作伙伴可通过App快速入网。</div>
        </div>
        <div class="userBox">
          <div class="serBox">
            <div class="imgBox">
              <div class="serImg">
                <img src="../../assets/home/Service.png" width="100%" />
              </div>
              <!-- <div class="serText">
                <div class="li"></div>
                服务商: 通过APP进行业务拓展。
              </div> -->
            </div>

            <div class="serText">
              <div class="li"></div>
              服务商: 通过APP进行业务拓展。
            </div>
          </div>
          <div class="serBox right">
            <div class="imgBox mer">
              <div class="merImg">
                <img src="../../assets/home/Merchant.png" width="100%" />
              </div>
              <!-- <div class="serText">
                <div class="li"></div>
                商户: 主要满足商户随时随地进行收款，配合POS
                <div class="ss">终端， 为商户提供POS收款服务。</div>
              </div> -->
            </div>

            <div class="serText">
              <div class="li"></div>
              商户: 主要满足商户随时随地进行收款，配合POS
              <div class="ss">终端， 为商户提供POS收款服务。</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务商功能 -->
      <div class="serviceBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">服务商功能</div>
          <div class="titSign"><img src="../../assets/home/titSign.png" width="100%" /></div>
        </div>
        <div class="itemBox">
          <div v-for="(item, index) in serviceList" ::key="index">
            <div class="itemIcon">
              <img :src="item.icon" width="100%" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 商户主要功能 -->
      <div class="serviceBox merBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">商户主要功能</div>
          <div class="titSign"><img src="../../assets/home/titSign.png" width="100%" /></div>
        </div>
        <div class="itemBox">
          <div v-for="(item, index) in merList" ::key="index">
            <div class="itemIcon">
              <img :src="item.icon" width="100%" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="advantageBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">优势</div>
          <div class="titSign"><img src="../../assets/home/titSign.png" width="100%" /></div>
        </div>
        <div class="itemBox">
          <div class="itemImg">
            <img src="../../assets/home/POS.png" />
            <!-- <div class="itemName">移动POS</div>
            <div class="itemDec">外观小巧，方便携带，支持全部银联卡，支付宝，微信，云闪付。</div> -->
          </div>

          <div class="itemImg">
            <img src="../../assets/home/security.png" />
            <!-- <div class="itemName">安全认证</div>
            <div class="itemDec">通过银联，银行卡POS检测中心双重认证</div> -->
          </div>
        </div>
      </div>
      <!-- 版权 -->
      <div class="footer">
        <div class="info">
          <div>公司：联动优势电子商务有限公司</div>

          <div>
            <span>地址：北京市石景山区古城南街9号院1号楼天安人寿中心大厦22层</span>
            <span class="info-tel">地址：深圳市龙华区民治街道民强社区优城北区AB座2101</span>
          </div>
          <div>客服电话：400-1125-882</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="copyright">
        <div>Copyright2022 中天数字研究（深圳）有限公司 版权所有</div>
        <div @click="go" class="info-num">粤ICP备2022104072号-1</div>
      </div>
    </div>
    <div class="backTop" v-if="btnFlag" @click="backTop"><img src="../../assets/home/top.png" /></div>
  </div>
</template>

<script>
import "animate.css";
import { WOW } from "wowjs";
export default {
  data() {
    return {
      headerScroll: false, //透明判断
      serviceList: [
        {
          name: "商户入网",
          icon: require("../../assets/home/int.png"),
        },
        {
          name: "商户查询",
          icon: require("../../assets/home/search.png"),
        },
        {
          name: "终端管理",
          icon: require("../../assets/home/terminal.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "分享",
          icon: require("../../assets/home/share.png"),
        },
      ],
      merList: [
        {
          name: "快捷交易",
          icon: require("../../assets/home/quick.png"),
        },
        {
          name: "交易结算查询",
          icon: require("../../assets/home/transaction.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "磁条大额认证",
          icon: require("../../assets/home/authentication.png"),
        },
        {
          name: "变更结算",
          icon: require("../../assets/home/change.png"),
        },
      ],
      advantageList: [
        {
          name: "移动POS",
          url: require("../../assets/home/POS.png"),
          dec: "外观小巧，方便携带，支持全部银联卡，支付宝，微信，云闪付。",
        },
        {
          name: "安全认证",
          url: require("../../assets/home/security.png"),
          dec: "通过银联，银行卡POS检测中心双重认证",
        },
      ],
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.$nextTick(() => {
      //页面渲染完，在执行
      let wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },

  methods: {
    go() {
      // location.href = "https://beian.miit.gov.cn/#/Integrated/index";
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    // 点击回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const upRoll = setInterval(() => {
        const top = document.documentElement.scrollTop; // 每次获取页面被卷去的部分
        const speed = Math.ceil(top / 10); // 每次滚动多少 （步长值）
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed; // 不在顶部 每次滚动到的位置
        } else {
          clearInterval(upRoll); // 回到顶部清除定时器
        }
      }, 10);
    },

    // 为了计算距离顶部的高度，当高度大于600显示回顶部图标，小于600则隐藏
    scrollToTop() {
      let that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.scrollTop > 92 ? (that.headerScroll = true) : (that.headerScroll = false);
      if (that.scrollTop > 600) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content-box {
  width: 100%;
  background: #ffffff;
  height: 100%;
  min-width: 1200px;
  .header {
    position: fixed;
    left: 0;
    top: 0;
    height: 92px;
    background: #fff;
    width: 100%;
    z-index: 9999;
    &.active {
      background: transparent;
    }
    .logo {
      margin-top: 18px;
      margin-left: 160px;
      width: 163px;
      height: 56px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    margin-top: 92px;
    position: relative;
    .sign {
      height: 740px;
      width: 100%;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fff;
      .signInfo {
        background: #fff;
        height: 100%;
        display: flex;
        .textInfo {
          //   position: absolute;
          //   top: 321px;
          margin-top: 261px;
          flex: 0.43;
          .title {
            //   width: 706px;
            //   height: 114px;
            font-size: 83px;
            font-family: AlibabaPuHuiTiB;
            color: #333333;
            //   line-height: 114px;
          }
          .signs {
            margin-top: 32px;
            font-size: 52px;
            font-family: AlibabaPuHuiTiM;
            color: #666666;
          }
        }
        .gifBox {
          //   position: absolute;
          //   top: 87px;
          //   right: 0;
          width: 687px;
          height: 687px;
          margin-top: 122px;
          flex: 0.57;
          display: flex;
          // justify-content: end;
          justify-content: flex-end;
          .gif {
            width: 546px;
            height: 546px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .introduceBOX {
      height: 860px;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fbfbfb;
      position: relative;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .introduce {
        text-align: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 50px;
        line-height: 28px;
        letter-spacing: 1px;
      }
      .userBox {
        // top: 396px;
        // position: absolute;
        margin-top: 130px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        .serBox {
          flex: 1;
          // text-align: center;
          // display: flex;
          // flex-wrap: wrap;
        }
        .right {
          justify-content: center;
        }
        .serImg {
          //   margin-left: 130px;
          width: 280px;
          height: 280px;
        }
        .imgBox {
          display: flex;
          justify-content: center;
          &:hover {
            transform: translateY(-6px);
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            box-shadow: 0 4px 8px 0 transparent;
            cursor: pointer;
          }
        }
        .merImg {
          text-align: center;
          width: 280px;
          height: 280px;
        }
        .mer {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
        .serText {
          margin-left: 60px;
          font-size: 22px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          color: #333333;
          line-height: 28px;
          letter-spacing: 1px;
          display: inline-block;
          text-align: center;
          .li {
            width: 29px;
            height: 29px;
            background: #ff3c3d;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
          .ss {
            text-align: left;
            margin-left: 35px;
          }
        }
      }
    }
    .serviceBox {
      height: 860px;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fff;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .itemBox {
        margin-top: 202px;
        display: flex;
        justify-content: space-between;
        .itemIcon {
          &:hover {
            transform: translateY(-6px);
            transition-duration: 0.5s;
            box-shadow: 0 4px 8px 0 transparent;
            cursor: pointer;
          }
        }
        .text {
          margin-top: 47px;
          text-align: center;
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    .merBox {
      background: #fbfbfb;
    }
    .advantageBox {
      height: 860px;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fff;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .itemBox {
        margin-top: 202px;
        display: flex;
        justify-content: space-between;
        .itemImg {
          position: relative;
          &:hover {
            transform: translateY(-6px);
            transition-duration: 0.5s;
            box-shadow: 0 4px 8px 0 transparent;
            cursor: pointer;
          }
        }
        .itemName {
          position: absolute;
          left: 50px;
          top: 188px;
          font-size: 30px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
        .itemDec {
          position: absolute;
          left: 50px;
          top: 248px;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .footer {
      height: 180px;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fbfbfb;
      .info {
        padding: 34px 0 40px 0px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
        .info-tel {
          margin-left: 100px;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e0e0e0;
    }
    .copyright {
      box-sizing: border-box;
      padding: 30px 0 30px 0;
      height: 128px;
      background: #fbfbfb;
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      .info-num {
        cursor: pointer;
      }
    }
  }
  .backTop {
    width: 75px;
    height: 75px;
    position: fixed;
    right: 32px;
    bottom: 130px;
    cursor: pointer;
    &:hover {
      animation: shake 800ms ease-in-out;
    }
  }
  @keyframes shake {
    /* 水平抖动，核心代码 */
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(+2px, 0, 0);
    }
    30%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(+4px, 0, 0);
    }
    50% {
      transform: translate3d(-4px, 0, 0);
    }
  }
}
a {
  outline: none;
}
</style>
